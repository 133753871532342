import React, { useState, ChangeEvent, FormEvent } from "react";
import { Send, Phone, Mail, MessageCircle } from "lucide-react";
import emailjs from "emailjs-com";

import "./support.css";

interface FormData {
  name: string;
  email: string;
  subject: string;
  message: string;
}

const SupportPage: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = (): boolean => {
    if (!formData.name.trim()) {
      alert("Name is required");
      return false;
    }
    if (!formData.email.trim()) {
      alert("Email is required");
      return false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      alert("Invalid email");
      return false;
    }
    if (!formData.subject.trim()) {
      alert("Subject is required");
      return false;
    }
    if (!formData.message.trim()) {
      alert("Message is required");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isSubmitting) {
      return; // Ngăn chặn gửi nhiều lần
    }

    if (!validateForm()) {
      return; // Dừng nếu form không hợp lệ
    }

    setIsSubmitting(true);

    const formObject: Record<string, unknown> = Object.entries(formData).reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {} as Record<string, unknown>);

    console.log("Form submitted:", formData);

    try {
      const result = await emailjs.send("service_b0k6ksx", "template_mdn631a", formObject, "rC3biFLUBNNelAfO8");
      console.log(result.text);
      // Xử lý kết quả thành công
      alert("Email sent successfully!");
      // Reset form sau khi gửi thành công
      setFormData({ name: "", email: "", subject: "", message: "" });
    } catch (error) {
      console.error("Error sending email:", error);
      alert("An error occurred while sending the email. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="support-page">
      <h1>Customer Support</h1>

      <div className="contact-info">
        <h2>Contact Information</h2>
        <ul>
          <li>
            <Mail />
            <span>Email: </span>
            <a href="mailto:support@appslife.one">
              <span>support@appslife.one</span>
            </a>
          </li>
          <li>
            <MessageCircle />
            <span>Live chat on: </span>
            <a href="https://t.me/appslifeone" target="_blank" rel="noopener noreferrer">
              Telegram
            </a>
          </li>
        </ul>
      </div>

      <div className="support-form">
        <h2>Send Support Request</h2>
        <form onSubmit={handleSubmit}>
          <input type="text" name="name" placeholder="Full Name" value={formData.name} onChange={handleChange} />
          <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} />
          <input type="text" name="subject" placeholder="Subject" value={formData.subject} onChange={handleChange} />
          <textarea name="message" placeholder="Message" value={formData.message} onChange={handleChange}></textarea>
          <button type="submit">
            <Send /> Send Request
          </button>
        </form>
      </div>
    </div>
  );
};

export default SupportPage;
