import { Link } from "react-router-dom";
import "./home.css";

export default function Home() {
  const apps = [
    {
      icon: "/icons/prcode.svg",
      className: "app-svg-red",
      backgroundColor: "white",
      name: "Prcode",
      description: "Scan barcodes, QR codes, and products.",
      platforms: [
        { name: "iOS", url: "https://apps.apple.com/app/id6619882005" },
        { name: "Android", url: "https://play.google.com/store/apps/details?id=com.example" },
      ],
    },
    // {
    //   icon: "/icons/prcode.svg",
    //   className: "app-svg-red",
    //   backgroundColor: "white",
    //   name: "Prcode",
    //   description: "Scan barcodes, QR codes, and products.",
    //   platforms: [
    //     { name: "iOS", url: "https://apps.apple.com/app/id6619882005" },
    //     { name: "Android", url: "https://play.google.com/store/apps/details?id=com.example" },
    //   ],
    // },
    // {
    //   icon: "/icons/prcode.svg",
    //   className: "app-svg-red",
    //   backgroundColor: "white",
    //   name: "Prcode",
    //   description: "Scan barcodes, QR codes, and products.",
    //   platforms: [
    //     { name: "iOS", url: "https://apps.apple.com/app/id6619882005" },
    //     { name: "Android", url: "https://play.google.com/store/apps/details?id=com.example" },
    //   ],
    // },
    // {
    //   icon: "/icons/prcode.svg",
    //   className: "app-svg-red",
    //   backgroundColor: "white",
    //   name: "Prcode",
    //   description: "Scan barcodes, QR codes, and products.",
    //   platforms: [
    //     { name: "iOS", url: "https://apps.apple.com/app/id6619882005" },
    //     { name: "Android", url: "https://play.google.com/store/apps/details?id=com.example" },
    //   ],
    // },
  ];

  return (
    <div className="app-container">
      <div className="app-grid">
        {apps.map((app) => (
          <div key={app.name} className="app-item">
            <div className="app-icon" style={{ backgroundColor: app.backgroundColor }}>
              <img src={app.icon} className={app.className} alt={`${app.name} icon`} />
            </div>
            <h3>{app.name}</h3>
            <p>{app.description}</p>
            <ul className="platform-list">
              {app.platforms.map((platform) => (
                <li key={platform.name}>
                  <a href={platform.url} target="_blank" rel="noopener noreferrer">
                    {platform.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="support">
        <p>
          For any inquiries, please visit our <Link to="/support">Support page</Link>.
        </p>
      </div>
    </div>
  );
}
